<template>
  <div>
    <div v-if="staffLoading" class="skeleton-card-wrapper">
      <v-skeleton-loader type="image" class="skeleton-card"></v-skeleton-loader>
      <v-skeleton-loader type="image" class="skeleton-card"></v-skeleton-loader>
      <v-skeleton-loader type="image" class="skeleton-card"></v-skeleton-loader>
    </div>
    <div v-else class="users-list" :class="{ details: showDetails }">
      <div
        v-for="staffItem in staffList"
        :key="staffItem.id"
        class="user-item user-info"
        :class="{ active: staffItem.user.id === staff.userId }"
        @click="getStaffById(staffItem.user.id)"
      >
        <div class="avatar">
          <img v-if="staffItem.avatar" :src="staffItem.avatar" alt="" />
          <img v-else src="@/assets/images/avatar.svg" alt="" />
        </div>
        <div class="text">
          <div class="name">
            {{ staffItem.user.first_name }} {{ staffItem.user.last_name }}
          </div>
          <div class="user-status">{{ staffItem.position }}</div>
        </div>
      </div>
    </div>

    <div class="user-details" v-if="showDetails">
      <div class="side-modal-wrap">
        <div class="hide" @click="closeDetails">
          Скрыть <img src="@/assets/images/house-arrow.svg" alt="" />
        </div>
        <div class="executor-info">
          <div class="avatar">
            <img v-if="staff.avatar" :src="staff.avatar" alt="" />
            <img v-else src="@/assets/images/avatar-lg.svg" alt="" />
          </div>
          <div class="text">
            <div class="name">{{ staff.name }}</div>
            <div v-if="staff.department.length > 0" class="position-department">
              <div class="job-status">{{ staff.position }}</div>
              <div class="d-flex">
                <v-chip
                  v-for="department in staff.department"
                  :key="department.department_id"
                  label
                  class="job-status"
                >
                  {{ department.department_title }}
                </v-chip>
              </div>
            </div>
            <div class="job-status" v-else>{{ staff.position }}</div>
          </div>
        </div>
        <div class="executor-description">
          <div class="item">
            <div class="left">Телефон</div>
            <div class="right">
              {{ staff.phone | VMask("+# (###) ###-##-##") }}
            </div>
          </div>
          <div class="item">
            <div class="left">График работы</div>
            <div class="right" v-if="staff.schedule">
              {{ staff.schedule.start_at }}-{{ staff.schedule.end_at }} •
              {{ staff.schedule.title }}
            </div>
            <div class="right" v-else>-</div>
          </div>
          <div class="item">
            <div class="left">E-mail</div>
            <div class="right">{{ staff.email ? staff.email : "-" }}</div>
          </div>
          <div class="item">
            <div class="left">ИИН</div>
            <div class="right">{{ staff.idn ? staff.idn : "-" }}</div>
          </div>
          <div class="item">
            <div class="left">Номер УДВ</div>
            <div class="right">{{ staff.udv ? staff.udv : "-" }}</div>
          </div>
        </div>
        <div
          class="doc-block"
          v-if="staff.contract != 'https://app.dev.domme.kznull'"
        >
          <div class="image">
            <img src="@/assets/images/file.svg" alt="" />
          </div>
          <div class="text">
            <div class="doc-name">Трудовой договор</div>
            <a class="open" :href="staff.contract" download target="_blanc"
              >Открыть</a
            >
          </div>
        </div>
        <div class="worker-raiting">
          <div class="item">
            <div class="left">Средняя оценка УК</div>
            <div class="right">
              <v-rating
                v-model="staff.rating_company"
                background-color="orange"
                size="20"
                color="orange"
                readonly
                dense
              ></v-rating>
            </div>
          </div>
          <div class="item" style="align-items: center">
            <div class="left">Средняя оценка пользователей</div>
            <div class="right">
              <v-rating
                v-model="staff.rating_resident"
                background-color="orange"
                size="20"
                color="orange"
                readonly
                dense
              ></v-rating>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-row">
        <CreateStaff
          :isEditingStaff="true"
          :userObj="staff"
          @update="updateUser"
        />
        <div class="delete-btn" @click="showDeleteUser = true">Удалить</div>
      </div>
    </div>
    <div v-show="showDeleteUser">
      <DeleteUser
        :item="staff"
        :type="type"
        :userId="staff.id"
        @close="showDeleteUser = false"
        @closeDetails="closeDetails"
        @updateList="init"
      />
    </div>
  </div>
</template>
<script>
import { staffApi, getStaffByIdApi } from "@/api/staff";
import {
  getDepartmentsApi,
  getDepartmentsOfComplexApi,
  getStaffByDepartmentApi,
} from "@/api/departments";
import { getScheduleApi, getStaffScheduleByStaffIdApi } from "@/api/schedule";
import DeleteUser from "../../components/modals/DeleteUser";
import CreateStaff from "../../components/modals/CreateStaff";

export default {
  components: {
    CreateStaff,
    DeleteUser,
    StarIcon: () => import("@/components/ui/icons/star"),
  },
  props: {
    filters: Object,
  },
  data: () => ({
    staffLoading: true,
    staffList: [],
    scheduleList: [],
    departmentsOfComplex: [],
    staff: {},
    showDetails: false,
    showDeleteUser: false,
  }),
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        const filters = {};
        Object.keys(this.filters).map((key) => {
          if (!!this.filters[key] && key === "debt") {
            filters[key] = this.filters[key];
          }
        });
        this.getStaff(filters);
      },
    },
  },
  created() {
    this.init();
  },
  computed: {
    type() {
      return this.$route.name.split(".")[1];
    },
  },
  methods: {
    init() {
      this.getStaff();
      this.getScheduleList();
      this.getDepartmentsOfComplex();
    },
    async getStaff(filters) {
      await staffApi(filters).then((res) => {
        this.staffList = res.data;
        // this.staffList = res.data.map((data) => ({
        //   id: data.id,
        //   userId: data.user?.id,
        //   name: `${data.user?.first_name} ${data.user?.last_name}`,
        //   position: data.position,
        //   avatar: data.user_avatar?.avatar && process.env.VUE_APP_API_STATIC + data.user_avatar?.avatar,
        // }));
      });
      this.staffLoading = false;
    },
    async getScheduleList() {
      let res = await getScheduleApi();
      this.scheduleList = [...res.data];
    },
    async getDepartmentsOfComplex() {
      this.departmentsOfComplex = [];
      try {
        let res = await getDepartmentsOfComplexApi();
        let departments = [...res.data];
        departments.forEach((department) => {
          getStaffByDepartmentApi(department.department_id).then((response) => {
            this.departmentsOfComplex = [
              ...this.departmentsOfComplex,
              {
                id: department.id,
                department_id: department.department_id,
                department_code: department.department_code,
                department_title: department.department_title,
                staff: response.data,
              },
            ];
          });
        });
      } catch (error) {
        throw error;
      }
    },
    updateUser(id) {
      this.getStaffById(id).then(() => {
        this.init();
      })
    },
    async getStaffById(id) {
      let resStaff = await getStaffByIdApi(id);
      const user = resStaff.data;

      this.staff = {
        id: user.id,
        position: user.position,
        contract: user.contract,
        userId: user.user?.id,
        name: `${user.user?.first_name} ${user.user?.last_name}`,
        email: user.user?.email,
        phone: user.user?.phone,
        idn: user.idn,
        udv: user.id_number,
        avatar:
          user.user_avatar?.avatar &&
          process.env.VUE_APP_API_STATIC + user.user_avatar?.avatar,
        rating_company: user.rating_company,
        rating_resident: user.rating_resident,
        schedule: undefined,
        department: [],
      };

      let resSchedule = await getStaffScheduleByStaffIdApi(id);
      console.log('resSchedule', resSchedule.data);
      const userSchedule = resSchedule.data.find(
        (schedule) => schedule.staff === id
      );
      
      if (userSchedule) {
        // Calculation for end time of schedule
        let endTime = this.$moment(
          new Date("1970-01-01 " + userSchedule.start_at)
        ).add(userSchedule.wh, "hours");

        // Find schedule for staff in schedule list
        let scheduleFromList = this.scheduleList.find(
          (schedule) => schedule.id === JSON.stringify(userSchedule.schedule)
        );

        this.staff.schedule = {
          id: scheduleFromList.id,
          title: scheduleFromList.title,
          start_at: userSchedule.start_at.substring(0, 5),
          end_at: endTime._d.toTimeString().substring(0, 5),
          work_hour: userSchedule.wh,
        };
      }

      this.staff.department = this.departmentsOfComplex.filter(
        (currentDepartment) =>
          currentDepartment.staff.find(
            (currentStaff) => currentStaff.user.id === id
          )
      );

      if (this.showDetails === true) {
        this.showDetails = false;
        this.showDetails = true;
      } else {
        this.showDetails = !this.showDetails;
        this.$emit("resizeTabsRow");
      }
      console.log("staff", this.staff);
    },
    closeDetails() {
      this.staff = {};
      this.showDetails = false;
      this.$emit("resizeTabsRow");
    },
  },
};
</script>

<style>
</style>